<template>
  <VueDatePicker
    :modelValue="props.value"
    @update:modelValue="handleUpdate"
    format="yyyy-MM-dd"
    :disabled="formState?.disabled || disabled"
    :placeholder="placeholder"
    :disabledDates="disabledDate"
    class="gum-date-picker"
    autoApply
    :textInput="{ format: 'yyyy-MM-dd' }"
    hideInputIcon
    :enableTimePicker="false"
    inputClassName="ant-input"
  >
    <template #clear-icon="{ clear }">
      <CloseCircleFilled class="gum-date-picker-clear" @click="clear" />
    </template>
  </VueDatePicker>
</template>

<script setup lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { useFormState } from './global-state'
import dayjs from 'dayjs/esm'
import { watch } from 'vue'
import { CloseCircleFilled } from '@ant-design/icons-vue'
import { getEvent } from '@/pages/Events/data/getEvent.gql'

const formState = useFormState()

const props = defineProps<{
  value: string | null | undefined
  disabled?: boolean
  minDate?: string | null
  placeholder?: string
  eventId?: string
}>()

const { event } = getEvent({ id: props.eventId || '' })

const emit = defineEmits<{
  (e: 'update:value', value: string | null): void
}>()

const handleUpdate = (value: Date | null) => {
  emit('update:value', value !== null ? dateToString(value) : null)
}

const dateToString = (date: Date | null): string => (date ? dayjs(date).format('YYYY-MM-DD') : '')

const disabledDate = (date: Date): boolean => {
  if (!props.minDate) {
    return false
  }

  return dayjs(date).isBefore(dayjs(props.minDate))
}

// Set default value when event.startDate is available
watch(
  () => event.value?.startDate,
  (startDate) => {
    if (startDate && !props.value) {
      handleUpdate(dayjs(startDate, 'UTC').toDate())
    }
  },
  { immediate: true }
)

// Ensure the value is not before minDate
watch(
  () => props.minDate,
  (newMinDate) => {
    if (newMinDate && props.value && dayjs(newMinDate).isAfter(dayjs(props.value))) {
      handleUpdate(dayjs(newMinDate).toDate())
    }
  }
)
</script>

<style lang="less" scoped>
.gum-date-picker {
  &:deep(.dp__theme_light) {
    --dp-font-size: 14px;
    --dp-primary-color: #1677ff;
    --dp-text-color: rgba(0, 0, 0, 0.85);
  }

  &:hover:deep(.gum-date-picker-clear) {
    opacity: 0.6;
  }

  &:deep(.ant-input) {
    font-size: 14px;
    line-height: 1.5715;
    padding: 4px 11px;
  }

  &:deep(.gum-date-picker-clear) {
    opacity: 0;
    position: relative;
    right: 11px;
    transition: 300ms;

    &:hover {
      opacity: 1;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }
}
</style>
