import { useQuery } from '@vue/apollo-composable'
import { computed } from 'vue'
import gql from 'graphql-tag'
import {
  type GetEventQuery,
  type GetEventQueryVariables,
  type GetEvent_EventFragment
} from './getEvent.gql.generated'

export type Event = GetEvent_EventFragment

export const getEvent = ({ id }: { id: string }) => {
  const { result, loading, refetch } = useQuery<GetEventQuery, GetEventQueryVariables>(
    gql`
      query GetEvent($id: ID!) {
        event(id: $id) {
          ...GetEvent_Event
        }
      }

      fragment GetEvent_Event on Event {
        id
        name
        location
        startDate
        endDate
        isArchived
      }
    `,
    { id }
  )

  const event = computed(() => result.value?.event as Event | null)

  return { event, loading, refetch }
}
